.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo-container {
      margin: 24px 0px;
    }
  }
  .login {
    margin: 24px 0px;
  }
}
