.home-page {
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  .ota {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1500px;
    flex: 1;
    > * {
      margin: 36px 0px;
    }

    .thingid-input {
      min-width: 700px;
    }
  }
  .status {
    padding: 24px;
    flex: 1;
    border-top: 1px solid #ccc;
    max-width: 1500px;
    min-height: 200px;
    min-width: 800px;

    .job-item {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .job-item-id {
      font-size: 12px;
      margin: 0 24px;
    }
    .job-start-date {
      margin: 0 24px;
    }
    .job-item-progress {
      margin: 0px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
